.bulk-search-table-container {
  margin-bottom: 20px;
  overflow-x: auto;
}

.bulk-search-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bulk-search-table th,
.bulk-search-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.bulk-search-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  color: #333;
}

.bulk-search-table tr:hover {
  background-color: #f5f5f5;
}

.bulk-search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}

.bulk-search-input:focus {
  border-color: #4CAF50;
  outline: none;
  box-shadow: 0 0 3px rgba(76, 175, 80, 0.3);
}

.remove-row-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-row-button:hover {
  background-color: #d32f2f;
}

.bulk-search-table-actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}

.add-row-button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-row-button:hover {
  background-color: #0b7dda;
}

/* Bulk input styles */
.bulk-input-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bulk-input-container h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
}

.bulk-input-instructions {
  margin-bottom: 10px;
  color: #666;
}

.bulk-input-example {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-family: monospace;
  color: #333;
  line-height: 1.5;
}

.bulk-input-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
  resize: vertical;
  min-height: 150px;
  box-sizing: border-box;
}

.bulk-input-textarea:focus {
  border-color: #2196F3;
  outline: none;
  box-shadow: 0 0 3px rgba(33, 150, 243, 0.3);
}

.bulk-input-actions {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
}

.bulk-input-submit,
.bulk-input-cancel {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.bulk-input-submit {
  background-color: #4CAF50;
  color: white;
}

.bulk-input-submit:hover {
  background-color: #45a049;
}

.bulk-input-cancel {
  background-color: #f44336;
  color: white;
}

.bulk-input-cancel:hover {
  background-color: #d32f2f;
}

@media (max-width: 768px) {
  .bulk-search-table th,
  .bulk-search-table td {
    padding: 8px 10px;
    font-size: 14px;
  }
  
  .bulk-search-input {
    padding: 6px;
    font-size: 13px;
  }
  
  .add-row-button {
    padding: 6px 12px;
    font-size: 13px;
  }
  
  .bulk-input-container {
    padding: 15px;
  }
  
  .bulk-input-textarea {
    min-height: 120px;
  }
  
  .bulk-input-submit,
  .bulk-input-cancel {
    padding: 6px 12px;
    font-size: 13px;
  }
}
