.mobile-game-selector {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  z-index: 10;
}

.mobile-selected-game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--wp8-blue);
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  height: 48px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.mobile-selected-game::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
  z-index: 1;
  pointer-events: none;
}

.mobile-selected-game span {
  position: relative;
  z-index: 2;
}

.dropdown-arrow {
  font-size: 12px;
  transition: transform 0.2s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.mobile-game-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  border: 1px solid #333;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 20;
}

.mobile-game-option {
  padding: 12px 16px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: background-color 0.2s ease;
  position: relative;
}

.mobile-game-option::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
  z-index: 1;
  pointer-events: none;
}

.mobile-game-option:hover {
  opacity: 0.9;
}

.mobile-game-option:active {
  opacity: 0.8;
}
