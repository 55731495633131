.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.save-buylist-popup {
  background-color: white;
  border-radius: 12px;
  padding: 35px;
  width: 95%;
  max-width: 750px; /* Increased from 500px (50% larger) */
  position: relative;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.25);
  animation: popup-fade-in 0.3s ease-out;
}

@keyframes popup-fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.save-buylist-popup h2 {
  margin-top: 0;
  color: #2196F3;
  font-size: 2rem;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 15px;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #999;
  transition: all 0.2s ease;
  z-index: 10;
}

.close-button:hover {
  color: #f44336;
  transform: rotate(90deg);
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #444;
  font-size: 1.1rem;
}

.form-group input {
  width: 100%;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.form-group input:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.2);
  background-color: #fff;
}

.cart-summary {
  background-color: #f5f9ff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 25px;
  border-left: 4px solid #2196F3;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.cart-summary p {
  margin: 10px 0;
  font-size: 1.1rem;
}

.cart-summary p strong {
  color: #333;
  font-weight: 600;
  margin-right: 5px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
}

.cancel-button, .save-button {
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.cancel-button {
  background-color: #f1f1f1;
  color: #555;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.cancel-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.save-button {
  background-color: #2196F3;
  color: white;
  flex-grow: 1;
  box-shadow: 0 2px 5px rgba(33, 150, 243, 0.3);
}

.save-button:hover:not(:disabled) {
  background-color: #0b7dda;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(33, 150, 243, 0.4);
}

.save-button:disabled {
  background-color: #90caf9;
  cursor: not-allowed;
  box-shadow: none;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 1rem;
  border-left: 4px solid #f44336;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 25px;
  border-radius: 8px;
  text-align: center;
  border-left: 4px solid #4CAF50;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.success-message p {
  margin: 12px 0;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .save-buylist-popup {
    width: 95%;
    padding: 25px;
  }
  
  .save-buylist-popup h2 {
    font-size: 1.6rem;
    padding-bottom: 12px;
    margin-bottom: 15px;
  }
  
  .form-actions {
    flex-direction: column;
    gap: 15px;
  }
  
  .cancel-button, .save-button {
    width: 100%;
    padding: 12px 20px;
  }
  
  .close-button {
    top: 15px;
    right: 15px;
    font-size: 1.6rem;
  }
}

@media (max-width: 480px) {
  .save-buylist-popup {
    padding: 20px;
  }
  
  .save-buylist-popup h2 {
    font-size: 1.4rem;
  }
  
  .form-group label {
    font-size: 1rem;
  }
  
  .form-group input {
    padding: 12px;
    font-size: 1rem;
  }
  
  .cart-summary p {
    font-size: 1rem;
  }
}
