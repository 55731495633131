.alt-sidebar {
  background-color: #f8f9fa;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.alt-sidebar-games {
  margin-bottom: 0;
}

.alt-sidebar-games h3 {
  font-size: 16px;
  font-weight: 600;
  color: #212529;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #4CAF50;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.alt-game-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.alt-game-item {
  padding: 12px 15px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: transparent;
  font-weight: 500;
}

.alt-game-item:hover {
  color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
  transform: translateX(5px);
}

.alt-game-item.active {
  color: white;
  background-color: #4CAF50;
  transform: translateX(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.alt-game-item.active .alt-star-icon {
  color: #fff;
}

.alt-star-icon {
  color: #FFD700;
  margin-right: 10px;
  font-size: 16px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .alt-sidebar {
    padding: 15px;
  }
  
  .alt-game-item {
    padding: 10px;
  }
  
  .alt-sidebar-games h3 {
    font-size: 14px;
  }
}
