.mobile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
}

.mobile-load-buylist-popup {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mobile-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-popup-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  flex: 1;
  text-align: center;
}

.mobile-close-button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-header-search-button {
  background-color: var(--wp8-blue);
  border: none;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0;
}

.mobile-header-search-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mobile-popup-content {
  padding: 16px;
  flex: 1;
  overflow-y: auto;
}

.mobile-popup-content p {
  margin-bottom: 16px;
  color: var(--secondary-text);
}

.mobile-error-message {
  background-color: rgba(229, 20, 0, 0.2);
  color: var(--wp8-red);
  padding: 12px;
  margin-bottom: 16px;
  border-left: 3px solid var(--wp8-red);
}

.mobile-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile-form-group label {
  font-size: 14px;
  color: var(--secondary-text);
}

.mobile-form-group input {
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: var(--text-color);
  font-size: 16px;
}

.mobile-form-actions {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.mobile-cancel-button,
.mobile-search-button,
.mobile-back-button,
.mobile-load-button {
  flex: 1;
  padding: 12px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}

.mobile-cancel-button,
.mobile-back-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
}

.mobile-search-button,
.mobile-load-button {
  background-color: var(--wp8-blue);
  color: white;
}

.mobile-buylist-results {
  margin-top: 24px;
}

.mobile-buylist-results h3 {
  font-size: 16px;
  margin-bottom: 12px;
}

.mobile-buylist-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-buylist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
}

.mobile-buylist-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mobile-buylist-date {
  font-size: 14px;
  color: var(--secondary-text);
}

.mobile-buylist-value {
  font-size: 16px;
  font-weight: 500;
}

.mobile-select-button {
  background-color: var(--wp8-blue);
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-buylist-details {
  padding: 16px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--secondary-text);
}

.mobile-buylist-summary {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px;
  margin-bottom: 16px;
}

.mobile-buylist-summary p {
  margin: 4px 0;
  font-size: 14px;
}

.mobile-selection-options {
  margin-bottom: 16px;
}

.mobile-selection-mode {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.mobile-selection-option {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

.mobile-selection-option.active {
  background-color: var(--wp8-blue);
}

.mobile-selection-option input {
  margin: 0;
}

.mobile-selection-controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.mobile-select-all-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  border: none;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-selection-summary {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--secondary-text);
  padding: 8px 0;
}

.mobile-buylist-items {
  flex: 1;
  overflow-y: auto;
}

.mobile-buylist-items h4 {
  font-size: 16px;
  margin-bottom: 12px;
}

.mobile-item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
}

.mobile-item.selected {
  background-color: rgba(38, 115, 209, 0.2);
}

.mobile-item-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.mobile-item-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.mobile-checkmark {
  height: 20px;
  width: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
}

.mobile-item-checkbox input:checked ~ .mobile-checkmark {
  background-color: var(--wp8-blue);
}

.mobile-item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mobile-item-name {
  font-size: 16px;
  font-weight: 500;
}

.mobile-item-details {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 14px;
  color: var(--secondary-text);
}

.mobile-item-condition,
.mobile-item-printing,
.mobile-item-quantity,
.mobile-item-price {
  display: inline-block;
}

.mobile-item-price {
  font-weight: 500;
  color: var(--text-color);
}
