.alt-game-grid {
  width: 100%;
  max-width: 1600px;
  padding: 30px;
  background-color: #f8f9fa;
}

.alt-game-grid h2 {
  font-size: 32px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.alt-grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
}

.alt-game-card {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #eaeaea;
}

.alt-game-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  border-color: #d0d0d0;
}

.alt-game-image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Square aspect ratio */
  overflow: hidden;
  background-color: #f0f0f0;
}

.alt-game-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.alt-game-image-inner {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  border-radius: 8px;
  max-width: none;
  max-height: none;
}

.alt-game-card:hover .alt-game-image-inner {
  transform: scale(1.05);
}

.alt-game-title {
  padding: 15px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #2c3e50;
  background-color: white;
  border-top: 1px solid #f0f0f0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .alt-grid-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
  
  .alt-game-grid h2 {
    font-size: 28px;
  }
}

@media (max-width: 900px) {
  .alt-grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .alt-game-title {
    font-size: 16px;
  }
}

@media (max-width: 520px) {
  .alt-grid-container {
    grid-template-columns: 1fr;
    max-width: 320px;
  }
  
  .alt-game-grid {
    padding: 20px;
  }
  
  .alt-game-grid h2 {
    font-size: 24px;
    margin-bottom: 25px;
  }
}
