/* App.css */

.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

/* Left column removed */

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.cart-container {
  width: 455px; /* Increased by 30% from 350px */
  padding: 20px;
  background-color: #f5f5f5;
  overflow-y: auto;
  border-left: 1px solid #ddd;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.cart-container.hidden {
  transform: translateX(100%);
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: -1;
}

.cart-container.visible {
  transform: translateX(0);
  opacity: 1;
  z-index: 10;
}

.cart-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-toggle-button::before {
  content: "🛒";
  margin-right: 8px;
  font-size: 16px;
}

.cart-toggle-button:hover {
  background-color: #43a047;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.cart-toggle-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.filters {
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.search-input {
  flex: 1;
  min-width: 150px;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 15px;
  transition: all 0.3s ease;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.search-input:focus {
  border-color: #2196F3;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 0 0 3px rgba(33, 150, 243, 0.15);
  outline: none;
}

.search-input::placeholder {
  color: #9e9e9e;
}

.search-button {
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button::before {
  content: "🔍";
  margin-right: 8px;
}

.search-button:hover {
  background-color: #43a047;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.3);
}

.search-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.2);
}

.filter-select {
  padding: 12px 16px;
  margin-right: 12px;
  margin-bottom: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  min-width: 200px;
  font-size: 15px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23757575' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.filter-select:focus {
  border-color: #2196F3;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 0 0 3px rgba(33, 150, 243, 0.15);
  outline: none;
}

.filter-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 15px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.pagination button {
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
  display: flex;
  align-items: center;
}

.pagination button:first-child::before {
  content: "◀";
  margin-right: 8px;
}

.pagination button:last-child::after {
  content: "▶";
  margin-left: 8px;
}

.pagination button:hover {
  background-color: #1e88e5;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(33, 150, 243, 0.3);
}

.pagination button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
}

.pagination button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.pagination span {
  font-size: 15px;
  color: #424242;
  font-weight: 500;
}

.showing-all-results {
  color: #616161;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 16px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

/* Button explanations removed */

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666;
}

.search-error {
  color: #f44336;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #ffebee;
  border-radius: 4px;
}

.translation-warning {
  color: #ff9800;
  margin-bottom: 15px;
  font-size: 14px;
}

.search-and-upload {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

/* Search mode toggle styles */
.search-mode-toggle {
  display: flex;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  gap: 2px;
  padding: 3px;
  background-color: #f0f0f0;
}

.search-mode-button {
  padding: 12px 24px;
  background-color: #2196F3;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
}

.search-mode-button::before {
  margin-right: 8px;
  font-size: 16px;
}

.search-mode-button:first-child::before {
  content: "🔍";
}

.search-mode-button:nth-child(2)::before {
  content: "📋";
}


/* Load Buylist button in main screen */
.load-buylist-button-main {
  background-color: #673AB7;
  color: white;
  box-shadow: 0 2px 4px rgba(103, 58, 183, 0.3);
}

.load-buylist-button-main::before {
  content: "📂";
}

/* Custom Buylist button in main screen */
.custom-buylist-button-main {
  background-color: #ff9800;
  color: white;
  box-shadow: 0 2px 4px rgba(255, 152, 0, 0.3);
}

.custom-buylist-button-main::before {
  content: "🛍️";
}

/* Custom Buylist container in main screen */
.main-custom-buylist {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff3e0;
  border-radius: 8px;
  border: 1px solid #ffe0b2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.main-custom-buylist h3 {
  margin-top: 0;
  color: #e65100;
  font-size: 1.4rem;
  margin-bottom: 20px;
  border-bottom: 1px solid #ffe0b2;
  padding-bottom: 10px;
}

.custom-buylist-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
}

.custom-buylist-table th,
.custom-buylist-table td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #ffe0b2;
}

.custom-buylist-table th {
  background-color: #fff8e1;
  font-weight: 600;
  color: #e65100;
}

.custom-buylist-table tr:hover {
  background-color: #fff8e1;
}

.custom-item-quantity {
  width: 60px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  text-align: center;
}

.add-custom-item-cash,
.add-custom-item-credit {
  padding: 8px 12px;
  margin: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.add-custom-item-cash {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #2e7d32;
}

.add-custom-item-cash:hover {
  background-color: #2e7d32;
  color: white;
}

.add-custom-item-credit {
  background-color: #e3f2fd;
  color: #1565c0;
  border: 1px solid #1565c0;
}

.add-custom-item-credit:hover {
  background-color: #1565c0;
  color: white;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .app {
    flex-direction: column;
    height: auto;
  }

  .cart-container.visible {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1000;
    border: none;
  }

  .cart-container.hidden {
    display: none;
  }

  .main-content {
    width: 100%;
  }

  .cart-toggle-button {
    top: 20px;
    right: 20px;
  }
}
