.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.notification-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.notification {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 400px;
  padding: 20px;
  transform: translateY(-20px);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: column;
}

.notification.visible {
  transform: translateY(0);
  opacity: 1;
}

.notification-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.notification-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  flex-shrink: 0;
}

.notification-icon.success {
  color: #4CAF50;
}

.notification-icon.error {
  color: #F44336;
}

.notification-icon.info {
  color: #2196F3;
}

.notification-message {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  flex-grow: 1;
}

.notification-close {
  align-self: flex-end;
  background-color: #1976D2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.notification-close:hover {
  background-color: #1565C0;
}

.notification-close:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.5);
}

/* Types */
.notification.success {
  border-left: 4px solid #4CAF50;
}

.notification.error {
  border-left: 4px solid #F44336;
}

.notification.info {
  border-left: 4px solid #2196F3;
}
