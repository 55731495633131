.mobile-search-bar {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  height: 48px;
}

.mobile-search-input {
  flex: 1;
  padding: 0 16px;
  font-size: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  border-radius: 0;
  height: 48px;
  box-sizing: border-box;
  line-height: 48px;
}

.mobile-search-input::placeholder {
  color: var(--secondary-text);
}

.mobile-search-input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
}

.mobile-search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  width: 48px;
  background-color: #007bff;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
}

.mobile-search-button svg {
  width: 24px;
  height: 24px;
}
