.mobile-tile-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: minmax(80px, auto);
  gap: 8px;
  margin-bottom: 24px;
}

.mobile-tile {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 12px;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.mobile-tile::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
  z-index: 1;
}

.mobile-tile:hover {
  transform: scale(0.98);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.mobile-tile:active {
  transform: scale(0.95);
}

.mobile-tile.large {
  grid-column: span 3;
  grid-row: span 3;
  min-height: 160px;
}

.mobile-tile.medium {
  grid-column: span 3;
  grid-row: span 2;
  min-height: 120px;
}

.mobile-tile.small {
  grid-column: span 2;
  grid-row: span 2;
  min-height: 100px;
}

.mobile-tile-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.mobile-tile-name {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: auto;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 2;
}

.mobile-tile.large .mobile-tile-name {
  font-size: 18px;
}


/* Responsive adjustments */
@media (max-width: 480px) {
  .mobile-tile-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .mobile-tile.large {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .mobile-tile.small {
    grid-column: span 2;
    grid-row: span 1;
  }
}

@media (max-width: 320px) {
  .mobile-tile-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
  
  .mobile-tile.large,
  .mobile-tile.medium,
  .mobile-tile.small {
    grid-column: span 1;
    grid-row: span 1;
    min-height: 80px;
  }
  
  .mobile-tile-name {
    font-size: 12px;
  }
}
