.confirmation-checkbox {
  margin: 20px 0;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 4px solid #4CAF50;
}

.confirmation-checkbox label {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #333;
}

.confirmation-checkbox input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

.close-x-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff4d4d;
  color: white;
  font-size: 24px;
  line-height: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: background-color 0.2s;
}

.close-x-button:hover {
  background-color: #ff0000;
}

.close-x-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Make sure the popup has relative positioning for absolute positioning of the X button */
.popup {
  position: relative;
}
