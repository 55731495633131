.mobile-checkout-page {
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

.mobile-checkout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #333333;
  width: 100%;
  box-sizing: border-box;
}

.mobile-home-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-cart-icon {
  font-size: 20px;
  padding: 5px;
}

.mobile-error {
  background-color: rgba(229, 20, 0, 0.2);
  color: #ff3333;
  padding: 10px;
  margin: 0 0 10px;
  border-left: 3px solid #ff3333;
  font-size: 14px;
}

.mobile-checkout-content {
  flex: 1;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}

.mobile-total {
  background-color: #111111;
  padding: 10px;
  text-align: right;
  font-size: 16px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-checkout-form {
  padding: 10px 0;
  margin-bottom: 20px;
}

.mobile-checkout-form h2 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.mobile-form-group {
  margin-bottom: 15px;
}

.mobile-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #cccccc;
}

.mobile-form-group input[type="text"],
.mobile-form-group input[type="email"],
.mobile-form-group textarea {
  width: 100%;
  padding: 10px;
  background-color: #222222;
  border: none;
  color: #ffffff;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 0;
}

.mobile-form-group textarea {
  min-height: 80px;
  resize: vertical;
}

.mobile-checkbox {
  display: flex;
  align-items: flex-start;
}

.mobile-checkbox input[type="checkbox"] {
  margin-right: 8px;
  margin-top: 2px;
}

.mobile-checkbox span {
  font-size: 13px;
}

.mobile-checkbox a {
  color: #3498db;
  text-decoration: none;
}

.mobile-button-group {
  margin-top: 20px;
}

.mobile-submit-button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 0;
}

.mobile-submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mobile-checkout-footer {
  margin-top: auto;
  padding: 10px;
  text-align: center;
  font-size: 11px;
  color: #999999;
  border-top: 1px solid #333333;
}

.mobile-checkout-footer a {
  color: #3498db;
  text-decoration: none;
}

.mobile-security-badges {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

.mobile-security-badge {
  background-color: #222222;
  padding: 3px 6px;
  font-size: 10px;
}

/* Responsive adjustments */
@media (max-width: 320px) {
  .mobile-checkout-content {
    padding: 0 8px;
  }
  
  .mobile-form-group input[type="text"],
  .mobile-form-group input[type="email"],
  .mobile-form-group textarea {
    font-size: 13px;
    padding: 8px;
  }
  
  .mobile-submit-button {
    font-size: 14px;
    padding: 10px;
  }
  
  .mobile-checkbox span {
    font-size: 12px;
  }
}
