.alt-search-section {
  width: 100%;
  margin-bottom: 20px;
}

.alt-search-form {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
}

.alt-search-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.alt-search-input {
  flex: 1;
  min-width: 150px;
  padding: 12px 15px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
  background-color: white;
}

.alt-search-input:focus {
  border-color: #4CAF50;
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
}

.alt-search-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 25px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 100px;
}

.alt-search-button:hover {
  background-color: #45a049;
}

.alt-search-button:active {
  background-color: #3d8b40;
}

/* Responsive styles */
@media (max-width: 768px) {
  .alt-search-inputs {
    flex-direction: column;
  }
  
  .alt-search-input {
    width: 100%;
  }
  
  .alt-search-button {
    width: 100%;
  }
}
