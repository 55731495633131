.bulk-search-results {
  margin-top: 30px;
}

.results-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Collapsible result groups */
.search-result-group {
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.search-query {
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.search-query:hover {
  background-color: #f0f0f0;
}

.search-query.added-to-cart {
  background-color: #e8f5e9;
  border-left: 4px solid #4CAF50;
}

.search-query.added-to-cart:hover {
  background-color: #d7eeda;
}

.added-to-cart-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #4CAF50;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 0 0 0 4px;
}

.search-query-text {
  font-weight: 500;
}

.search-query-text strong {
  margin-right: 5px;
}

.search-result-content {
  padding: 15px;
  max-height: 500px;
  overflow-y: auto;
  border-bottom: 1px solid #ddd;
}

.search-result-content.collapsed {
  display: none;
}

.collapse-icon {
  margin-left: 10px;
  font-size: 18px;
  transition: transform 0.3s;
}

.collapse-icon.expanded {
  transform: rotate(180deg);
}

/* Status badges */
.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  white-space: nowrap;
}

.status-pending {
  background-color: #ffeb3b;
  color: #333;
}

.status-searching {
  background-color: #2196F3;
  color: white;
}

.status-complete {
  background-color: #4CAF50;
  color: white;
}

.status-error {
  background-color: #f44336;
  color: white;
}

.error-message {
  color: #f44336;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffebee;
  border-radius: 4px;
}

.no-results-message {
  color: #666;
  margin: 15px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  text-align: center;
}

/* Multiple results selection */
.multiple-results {
  margin-top: 15px;
}

.result-selection {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.result-selection label {
  margin-right: 10px;
  font-weight: bold;
  white-space: nowrap;
}

.result-dropdown {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 300px;
  flex-grow: 1;
}

/* Card display */
.selected-card, .single-result {
  margin-top: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fafafa;
}

.card-details {
  display: flex;
  padding: 15px;
}

.card-image-container {
  flex: 0 0 120px;
  margin-right: 15px;
}

.card-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.card-info {
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
}

.card-name {
  font-size: 18px;
  margin: 0 0 5px 0;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-set, .card-number, .card-rarity {
  margin: 0 0 5px 0;
  color: #666;
  font-size: 14px;
}

/* Card options */
.card-options {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option-group {
  min-width: 120px;
}

.option-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}

.condition-select, .printing-select {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

/* Card actions */
.card-actions {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.add-to-cart-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.add-to-cart-button.credit {
  background-color: #2196F3;
  color: white;
}

.add-to-cart-button.credit:hover {
  background-color: #0b7dda;
}

.add-to-cart-button.cash {
  background-color: #4CAF50;
  color: white;
}

.add-to-cart-button.cash:hover {
  background-color: #45a049;
}

.add-to-cart-button.not-buying {
  background-color: #f5f5f5;
  color: #666;
  cursor: not-allowed;
}

/* Results grid for multiple cards */
.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.grid-card-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
}

/* Responsive styles */
@media (max-width: 768px) {
  .card-details {
    flex-direction: column;
  }
  
  .card-image-container {
    flex: 0 0 auto;
    margin-right: 0;
    margin-bottom: 15px;
    max-width: 120px;
  }
  
  .result-dropdown {
    min-width: 200px;
  }
  
  .card-actions {
    flex-direction: column;
  }
  
  .add-to-cart-button {
    width: 100%;
  }
  
  .results-grid {
    grid-template-columns: 1fr;
  }
}
