.order-completion-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.order-completion-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.order-completion-popup h2 {
  color: #4CAF50;
  margin-bottom: 15px;
}

.order-completion-popup p {
  margin-bottom: 15px;
}

.order-completion-popup .important-notice {
  background-color: #FFF3CD;
  border: 1px solid #FFEEBA;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  color: #856404;
}

.order-completion-popup button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.order-completion-popup button:hover {
  background-color: #45a049;
}

.order-completion-popup button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}