.alt-filter-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
}

.alt-filter-label {
  font-weight: 500;
  color: #495057;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 15px;
  min-width: 80px;
}

.alt-filter-select-container {
  position: relative;
  flex: 1;
}

.alt-filter-select {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  padding-right: 30px;
}

.alt-select-arrow {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #495057;
  pointer-events: none;
}

.alt-filter-select:focus {
  border-color: #4CAF50;
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
}

.alt-filter-select:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Responsive styles */
@media (max-width: 768px) {
  .alt-filter-row {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .alt-filter-label {
    margin-bottom: 10px;
  }
  
  .alt-filter-select-container {
    width: 100%;
  }
}
