.sku-details-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sku-details-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.sku-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.sku-details-section {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

.sku-details-section h3 {
  margin-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.sku-details-section table {
  width: 100%;
  border-collapse: collapse;
}

.sku-details-section td {
  padding: 8px 5px;
  border-bottom: 1px solid #eee;
}

.sku-details-section td:first-child {
  font-weight: bold;
  width: 40%;
}

.sku-details-footer {
  margin-top: 20px;
  text-align: center;
}

.price-point-info, .price-preference-order {
  font-style: italic;
  color: #666;
  margin-bottom: 10px;
}

.price-preference-order {
  font-size: 0.9em;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .sku-details-grid {
    grid-template-columns: 1fr;
  }
  
  .sku-details-content {
    width: 95%;
    padding: 15px;
  }
}
