.cart {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  transition: all 0.3s ease;
}

.cart-title {
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #2c3e50;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.cart-title::before {
  content: "🛒";
  margin-right: 10px;
  font-size: 1.4rem;
}

.cart-empty {
  text-align: center;
  color: #95a5a6;
  font-style: italic;
  padding: 30px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
}

.cart-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cart-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cart-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.cart-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.item-details {
  flex-grow: 1;
}

.item-name {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
  display: block;
  font-size: 1.05rem;
}

.item-specs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
}

.item-condition,
.item-printing,
.item-language {
  background-color: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

/* New styles for condition and printing selects in cart */
.item-condition-select,
.item-printing-select {
  padding: 6px 10px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 0.85em;
  background-color: #fff;
  margin-right: 5px;
  min-width: 100px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.item-condition-select:focus,
.item-printing-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.item-note {
  background-color: #fff3cd;
  color: #856404;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-right: 5px;
  cursor: help;
  display: inline-flex;
  align-items: center;
}

.item-note::before {
  content: "ℹ️";
  margin-right: 4px;
  font-size: 0.9em;
}

.item-language {
  background-color: #f8d7da;
  color: #721c24;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.item-language::before {
  content: "🌐";
  margin-right: 4px;
  font-size: 0.9em;
}

.item-price {
  color: #2c3e50;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.price-tag {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 16px;
  font-weight: 600;
  margin-top: 4px;
}

.price-cash {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.price-credit {
  background-color: #e3f2fd;
  color: #1565c0;
}

/* Reuse the derived price indicator styles from CardList.css */
.derived-price-indicator {
  display: inline-block;
  color: #ff4d4d;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: help;
}

.item-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 20px;
  padding: 2px;
  border: 1px solid #e0e0e0;
}

.quantity-button {
  background-color: #f8f9fa;
  border: none;
  color: #495057;
  font-size: 1rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.quantity-button:hover:not(:disabled) {
  background-color: #e9ecef;
  color: #212529;
}

.quantity-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.item-quantity {
  margin: 0 12px;
  font-weight: bold;
  color: #212529;
  min-width: 20px;
  text-align: center;
}

.remove-button {
  background-color: #fff;
  border: 1px solid #ff4d4d;
  color: #ff4d4d;
  font-size: 1rem;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 20px;
  transition: background-color 0.2s ease, color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-button:hover {
  background-color: #ff4d4d;
  color: white;
}

.cart-summary {
  margin-top: 24px;
  border-top: 2px solid #f0f0f0;
  padding-top: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.subtotal,
.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px 0;
}

.subtotal {
  color: #495057;
}

.total {
  font-weight: bold;
  font-size: 1.2rem;
  color: #212529;
  border-top: 1px dashed #dee2e6;
  padding-top: 12px;
  margin-top: 8px;
}

.checkout-button {
  width: 100%;
  padding: 14px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.2);
}

.checkout-button:hover {
  background-color: #43a047;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(76, 175, 80, 0.3);
}

.checkout-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.2);
}

.clear-cart-button {
  background-color: #fff;
  color: #f44336;
  border: 1px solid #f44336;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  transition: all 0.3s ease;
  font-weight: 500;
}

.clear-cart-button:hover {
  background-color: #f44336;
  color: white;
}

.cart-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}

.save-for-later-button, .load-buylist-button, .export-csv-button, .import-csv-button {
  background-color: #fff;
  color: #2196F3;
  border: 1px solid #2196F3;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-for-later-button:hover, .load-buylist-button:hover, .export-csv-button:hover, .import-csv-button:hover {
  background-color: #2196F3;
  color: white;
}

/* Dedicated large button for loading saved buylists */
.load-buylist-button-large {
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  cursor: pointer;
  width: 100%;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(63, 81, 181, 0.2);
}

.load-buylist-button-large:hover {
  background-color: #303f9f;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(63, 81, 181, 0.3);
}

.load-buylist-button-large:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(63, 81, 181, 0.2);
}

.load-buylist-button-large:disabled {
  background-color: #c5cae9;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.load-buylist-button-large::before {
  content: "📂";
  margin-right: 10px;
  font-size: 1.2rem;
}

.save-for-later-button {
  color: #673AB7;
  border: 1px solid #673AB7;
}

.save-for-later-button:hover {
  background-color: #673AB7;
  color: white;
}

.export-csv-button {
  color: #00796B;
  border: 1px solid #00796B;
}

.export-csv-button:hover {
  background-color: #00796B;
  color: white;
}

.export-csv-button:disabled {
  color: #9e9e9e;
  border-color: #9e9e9e;
  cursor: not-allowed;
  opacity: 0.7;
}

.export-csv-button:disabled:hover {
  background-color: #fff;
  color: #9e9e9e;
}

.import-csv-button {
  color: #FF9800;
  border: 1px solid #FF9800;
}

.import-csv-button:hover {
  background-color: #FF9800;
  color: white;
}

.import-csv-button::before {
  content: "📥";
  margin-right: 8px;
}

.export-csv-button::before {
  content: "📤";
  margin-right: 8px;
}

.save-for-later-button::before {
  content: "💾";
  margin-right: 8px;
}

.load-buylist-button::before {
  content: "📂";
  margin-right: 8px;
}

.email-valuation-button {
  width: 100%;
  padding: 12px;
  background-color: #fff;
  color: #2196F3;
  border: 1px solid #2196F3;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-valuation-button::before {
  content: "📧";
  margin-right: 8px;
}

.email-valuation-button:hover {
  background-color: #2196F3;
  color: white;
}

/* New styles for the toggle payment button */
.toggle-payment-button {
  padding: 6px 12px;
  font-size: 0.9rem;
  background-color: #fff;
  border: 1px solid #6c757d;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  transition: all 0.3s ease;
  color: #6c757d;
  display: flex;
  align-items: center;
}

.toggle-payment-button:hover {
  background-color: #f8f9fa;
  border-color: #5a6268;
  color: #5a6268;
}

.toggle-payment-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(108, 117, 125, 0.25);
}

.toggle-payment-button::before {
  content: "💱";
  margin-right: 6px;
  font-size: 0.9em;
}

/* Custom Buylist Items Styles */
.custom-buylist-button {
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px;
  margin: 16px 0;
  cursor: pointer;
  width: 100%;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(255, 152, 0, 0.2);
}

.custom-buylist-button:hover {
  background-color: #f57c00;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(255, 152, 0, 0.3);
}

.custom-buylist-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(255, 152, 0, 0.2);
}

.custom-buylist-button::before {
  content: "🛍️";
  margin-right: 10px;
  font-size: 1.2rem;
}

.custom-buylist-container {
  margin: 20px 0;
  padding: 16px;
  background-color: #fff3e0;
  border-radius: 8px;
  border: 1px solid #ffe0b2;
}

.custom-buylist-container h3 {
  margin-top: 0;
  color: #e65100;
  font-size: 1.2rem;
  margin-bottom: 16px;
  border-bottom: 1px solid #ffe0b2;
  padding-bottom: 8px;
}

.custom-buylist-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
}

.custom-buylist-table th,
.custom-buylist-table td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #ffe0b2;
}

.custom-buylist-table th {
  background-color: #fff8e1;
  font-weight: 600;
  color: #e65100;
}

.custom-buylist-table tr:hover {
  background-color: #fff8e1;
}

.custom-item-quantity {
  width: 60px;
  padding: 6px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  text-align: center;
}

.add-custom-item-cash,
.add-custom-item-credit {
  padding: 6px 10px;
  margin: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.add-custom-item-cash {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #2e7d32;
}

.add-custom-item-cash:hover {
  background-color: #2e7d32;
  color: white;
}

.add-custom-item-credit {
  background-color: #e3f2fd;
  color: #1565c0;
  border: 1px solid #1565c0;
}

.add-custom-item-credit:hover {
  background-color: #1565c0;
  color: white;
}

.custom-item-label {
  background-color: #fff3e0;
  color: #e65100;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 600;
}

/* Responsive styles */
@media (max-width: 768px) {
  .cart {
    padding: 16px;
  }
  
  .cart-item {
    padding: 12px;
  }
  
  .item-actions {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .quantity-control {
    margin-bottom: 10px;
  }
  
  .toggle-payment-button,
  .remove-button {
    margin-top: 8px;
  }
  
  .custom-buylist-table {
    display: block;
    overflow-x: auto;
  }
  
  .add-custom-item-cash,
  .add-custom-item-credit {
    display: block;
    width: 100%;
    margin: 4px 0;
  }
}
