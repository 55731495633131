.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.card-list.table-view {
  display: block;
}

.card {
  width: 200px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: visible; /* Changed from hidden to visible to allow content to flow */
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: auto; /* Allow height to adjust based on content */
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 10px auto;
}

.card-info {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: visible; /* Ensure content isn't being clipped */
  height: auto; /* Allow height to adjust based on content */
}

.card-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px 0;
  min-height: 96px; /* Fixed height for 4 lines (24px per line) */
  overflow: visible; /* Allow text to be fully visible */
  word-wrap: break-word; /* Allow long words to break and wrap */
  white-space: normal; /* Allow text to wrap to multiple lines */
  text-overflow: clip; /* Don't add ellipsis */
  display: block; /* Use standard block display instead of -webkit-box */
  line-height: 24px; /* Set line height to ensure consistent spacing */
}

.card-expansion {
  font-size: 12px;
  color: #666;
  margin: 0 0 4px 0;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-number {
  font-size: 12px;
  color: #666;
  margin: 0 0 4px 0;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-rarity {
  font-size: 12px;
  color: #444;
  font-weight: 500;
  margin: 0 0 8px 0;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-options {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.condition-select,
.printing-select,
.language-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f8f8;
  transition: border-color 0.3s ease;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.card-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.condition-select:focus,
.printing-select:focus {
  outline: none;
  border-color: #2196F3;
}

.card-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
}

.add-to-cart-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.card-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-to-cart-button:active {
  transform: scale(0.98);
}

.add-to-cart-button.cash {
  background-color: #4CAF50;
}

.add-to-cart-button.credit {
  background-color: #2196F3;
}

.add-to-cart-button:hover {
  opacity: 0.9;
}

.add-to-cart-button.bulk,
.add-to-cart-button.not-buying {
  background-color: #f0f0f0;
  color: #888;
  cursor: not-allowed;
}

.add-to-cart-button.bulk:hover,
.add-to-cart-button.not-buying:hover {
  opacity: 1;
}

.add-to-cart-button.not-buying,
.add-to-cart-button.cash.disabled {
  background-color: #ffcccc;
  cursor: not-allowed;
}

.add-to-cart-button.cash.disabled {
  opacity: 0.7;
}

.add-to-cart-button.contact-for-price {
  background-color: #FFA500; /* Amber color */
  color: #000; /* Black text for better contrast */
  cursor: default;
}

.add-to-cart-button.contact-for-price:hover {
  opacity: 1;
}

.price-timestamp {
  font-size: 12px;
  color: #888;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.price-timestamp:hover {
  color: #2196F3;
  text-decoration: underline;
}

/* Toggle Row Styles */
.toggle-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  gap: 20px; /* Add gap between toggles */
}

.bulk-toggle,
.view-toggle {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Table View Styles */
.card-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.card-table th,
.card-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-card-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
  vertical-align: middle;
  border-radius: 4px;
}

.card-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.card-table tr:hover {
  background-color: #f5f5f5;
}

.card-table select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f8f8;
}

.card-table .add-to-cart-button {
  padding: 6px 12px;
  font-size: 14px;
  margin-right: 5px;
}

.card-table .card-actions {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

/* Japanese language indicator styles */
.japanese-only-label {
  display: inline-block;
  background-color: #ff4d4d;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 10px;
}

.language-select:focus {
  outline: none;
  border-color: #2196F3;
}

/* Derived price indicator styles */
.derived-price-indicator {
  display: inline-block;
  color: #ff4d4d;
  font-weight: bold;
  margin-left: 3px;
  font-size: 16px;
  cursor: help;
}

.card-table .derived-price-indicator {
  margin-left: 2px;
  font-size: 14px;
}
