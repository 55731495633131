body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.alt-app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fixed height */
  background-color: white;
  overflow: hidden; /* Prevent scrolling */
}

.alt-main-container {
  display: flex;
  flex: 1;
  height: 100vh; /* Full viewport height since header is removed */
  overflow: hidden; /* Prevent scrolling */
}

.alt-sidebar-container {
  width: 250px;
  overflow: hidden; /* Prevent scrolling at container level */
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.alt-content-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.alt-cart-container {
  width: 468px; /* Reduced by 20% from 585px */
  border-left: 1px solid #eee;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.alt-card-list-view {
  width: 100%;
}

.alt-custom-items-view {
  width: 100%;
  padding: 20px 0;
}

.alt-custom-items-view h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  text-align: center;
}

.alt-custom-items-list {
  width: 100%;
  overflow-x: auto;
}

.alt-custom-items-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.alt-custom-items-table th,
.alt-custom-items-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.alt-custom-items-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #333;
}

.alt-custom-items-table tr:hover {
  background-color: #f5f5f5;
}

.alt-custom-items-table input[type="number"] {
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.alt-add-custom-item-cash,
.alt-add-custom-item-credit {
  padding: 6px 12px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.alt-add-custom-item-cash {
  background-color: #4CAF50;
  color: white;
}

.alt-add-custom-item-cash:hover {
  background-color: #45a049;
}

.alt-add-custom-item-credit {
  background-color: #2196F3;
  color: white;
}

.alt-add-custom-item-credit:hover {
  background-color: #0b7dda;
}

/* Back button removed as requested */

.alt-search-container {
  margin-bottom: 20px;
}

/* Game filters removed as requested */

.alt-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666;
}

.alt-pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Style for showing all results message in alternative UI */
.alt-pagination-container .showing-all-results {
  font-size: 14px;
  font-weight: bold;
  color: #4CAF50;
  background-color: #f0f8f0;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #c8e6c9;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .alt-main-container {
    flex-direction: column;
    height: auto;
  }

  .alt-sidebar-container {
    width: 100%;
    max-height: 300px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .alt-content-container {
    width: 100%;
  }

  .alt-cart-container {
    width: 100%;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 768px) {
  .alt-sidebar-container {
    max-height: 250px;
  }
  
  .alt-content-container {
    padding: 15px;
  }
  
  .alt-cart-container {
    padding: 10px;
  }
}
