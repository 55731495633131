.infographics {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f6f8fa, #ffffff);
  border-radius: 1rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 5px 10px -5px rgba(0, 0, 0, 0.04);
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.info-card {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.info-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.info-card-icon {
  background: linear-gradient(135deg, #4f46e5, #3b82f6);
  padding: 0.75rem;
  border-radius: 50%;
  margin-right: 1rem;
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.3);
}

.info-card-icon svg {
  color: white;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
}

.info-card h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.info-card p {
  color: #4b5563;
  line-height: 1.5;
  font-size: 0.95rem;
  margin: 0;
}

.tips-section {
  margin-top: 2rem;
  background: linear-gradient(135deg, #10b981, #3b82f6);
  padding: 1.5rem;
  border-radius: 0.75rem;
  color: white;
  box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.3), 0 4px 6px -2px rgba(59, 130, 246, 0.2);
}

.tips-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.tips-header svg {
  margin-right: 0.75rem;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
}

.tips-header h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.tips-section ul {
  list-style-type: none;
  padding-left: 0.5rem;
  margin: 0;
}

.tips-section li {
  margin-bottom: 0.5rem;
  position: relative;
  font-size: 0.95rem;
  line-height: 1.5;
}

.tips-section li::before {
  content: "•";
  position: absolute;
  left: -0.75rem;
  color: rgba(255, 255, 255, 0.8);
}

/* New style for the first list item in tips section */
.tips-section li:first-child {
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 1024px) {
  .info-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .infographics {
    padding: 1.5rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }

  .info-card h3 {
    font-size: 1rem;
  }

  .info-card p {
    font-size: 0.9rem;
  }
}

/* Subtle animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.info-card {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.info-card:nth-child(1) { animation-delay: 0.1s; }
.info-card:nth-child(2) { animation-delay: 0.2s; }
.info-card:nth-child(3) { animation-delay: 0.3s; }
.info-card:nth-child(4) { animation-delay: 0.4s; }
.info-card:nth-child(5) { animation-delay: 0.5s; }
.info-card:nth-child(6) { animation-delay: 0.6s; }

.tips-section {
  animation: fadeIn 0.5s ease-out 0.7s forwards;
  opacity: 0;
}