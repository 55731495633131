.mobile-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 20px 0;
}

.mobile-pagination-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  border-radius: 0;
}

.mobile-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mobile-pagination-pages {
  display: flex;
  align-items: center;
  gap: 4px;
}

.mobile-pagination-page {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  border: none;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0;
}

.mobile-pagination-page.active {
  background-color: var(--wp8-blue);
  color: white;
  font-weight: 500;
}

.mobile-pagination-ellipsis {
  color: var(--secondary-text);
  width: 24px;
  text-align: center;
  font-size: 16px;
}

/* Media Queries */
@media (max-width: 320px) {
  .mobile-pagination-button,
  .mobile-pagination-page {
    width: 36px;
    height: 36px;
    font-size: 14px;
  }
}
