.mobile-expansion-selector {
  width: 100%;
  margin-bottom: 12px;
}

.mobile-selector-label {
  display: block;
  font-size: 14px;
  color: var(--secondary-text);
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.mobile-expansion-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 8px;
}

.mobile-expansion-button {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: var(--text-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 8px;
}

.mobile-expansion-button.active {
  background-color: var(--wp8-blue);
}
