:root {
  --wp8-blue: #2673D1;
  --wp8-green: #5BA83A;
  --wp8-red: #E51400;
  --wp8-orange: #F09609;
  --wp8-purple: #8C0095;
  --wp8-teal: #00ABA9;
  --wp8-lime: #8CBF26;
  --wp8-magenta: #FF0097;
  --wp8-brown: #825A2C;
  --wp8-gray: #7E7E7E;
  
  --text-color: #FFFFFF;
  --secondary-text: rgba(255, 255, 255, 0.7);
  --background-color: #000000;
  --tile-background: rgba(255, 255, 255, 0.1);
  --header-height: 60px;
}

/* Base styles */
.mobile-app {
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
  padding-top: var(--header-height);
  font-family: 'Segoe UI', 'Roboto', sans-serif;
}

/* Home view */
.mobile-home-view {
  padding: 16px;
}


.mobile-game-tiles {
  padding: 16px;
}

.mobile-game-tiles h2 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Custom Items Tile */
.mobile-custom-items-tile-container {
  margin-top: 24px;
}

.mobile-custom-items-tile {
  background-color: var(--wp8-orange);
  border-radius: 4px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.mobile-custom-items-tile-content {
  text-align: center;
  padding: 16px;
}

.mobile-custom-items-tile-name {
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Store Website Tile */
.mobile-store-website-tile-container {
  margin-top: 24px;
}

.mobile-store-website-tile {
  background-color: var(--wp8-blue);
  border-radius: 4px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.mobile-store-website-tile-content {
  text-align: center;
  padding: 16px;
}

.mobile-store-website-tile-name {
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}


/* Search view */
.mobile-search-view {
  padding: 16px;
}

.mobile-filters {
  margin: 16px 0;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
}

.mobile-loading,
.mobile-initial-load {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  text-align: center;
}

/* Custom Items View */
.mobile-custom-items-view {
  padding: 16px;
}

.mobile-view-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
}

.mobile-view-header h2 {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.mobile-back-button {
  background: none;
  border: none;
  color: var(--wp8-blue);
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  position: absolute;
  left: 0;
}

.mobile-custom-items-container {
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  overflow: hidden;
}

.mobile-custom-items-header {
  background-color: var(--wp8-blue);
  color: white;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
}

.mobile-custom-item {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-custom-item-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}

.mobile-custom-item-description {
  font-size: 14px;
  color: var(--secondary-text);
  margin-bottom: 12px;
}

.mobile-custom-item-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.mobile-custom-item-price-label {
  font-size: 14px;
  color: var(--secondary-text);
}

.mobile-custom-item-price-value {
  font-weight: 500;
}

.mobile-custom-item-quantity {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.mobile-custom-item-quantity-label {
  font-size: 14px;
  color: var(--secondary-text);
  margin-right: 12px;
}

.mobile-custom-item-quantity-input {
  width: 60px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 4px;
  text-align: center;
}

.mobile-custom-item-actions {
  display: flex;
  gap: 8px;
}

.mobile-custom-item-add-cash {
  flex: 1;
  background-color: var(--wp8-green);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.mobile-custom-item-add-credit {
  flex: 1;
  background-color: var(--wp8-blue);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 320px) {
  .mobile-app {
    font-size: 14px;
  }
  
  .mobile-game-tiles h2 {
    font-size: 18px;
  }
}
