/* Checkout Page Container */
.checkout-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

/* Header */
.checkout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.checkout-header h1 {
  font-size: 28px;
  color: #2c3e50;
  margin: 0;
}

.back-button {
  background: none;
  border: none;
  color: #3498db;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.back-button:hover {
  color: #2980b9;
}

.back-arrow {
  margin-right: 5px;
}

/* Checkout Content */
.checkout-content {
  display: flex;
  gap: 40px;
}

/* Order Summary */
.order-summary {
  flex: 1;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.order-summary h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50;
}

/* Cart Item */
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #ecf0f1;
}

.item-details {
  flex: 2;
}

.item-name {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #2c3e50;
}

.item-info {
  font-size: 14px;
  color: #7f8c8d;
}

.item-price-quantity {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-price {
  font-weight: bold;
  margin-bottom: 5px;
  color: #2c3e50;
}

.item-quantity {
  display: flex;
  align-items: center;
}

.item-quantity button {
  background-color: #ecf0f1;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.item-quantity button:hover {
  background-color: #bdc3c7;
}

.item-quantity span {
  margin: 0 10px;
  font-weight: bold;
}

.item-actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.payment-toggle {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-toggle:hover {
  background-color: #2980b9;
}

.payment-toggle.cash {
  background-color: #2ecc71;
}

.payment-toggle.cash:hover {
  background-color: #27ae60;
}

.payment-toggle.credit {
  background-color: #e74c3c;
}

.payment-toggle.credit:hover {
  background-color: #c0392b;
}

.remove-item {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-item:hover {
  background-color: #c0392b;
}

/* Total */
.total {
  margin-top: 20px;
  text-align: right;
  font-size: 20px;
  color: #2c3e50;
}

/* Checkout Form */
.checkout-form {
  flex: 1;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkout-form h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #2c3e50;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group textarea:focus {
  border-color: #3498db;
  outline: none;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input {
  margin-right: 10px;
}

.checkbox span {
  font-size: 14px;
}

.checkbox a {
  color: #3498db;
  text-decoration: none;
}

.checkbox a:hover {
  text-decoration: underline;
}

/* Buttons */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.submit-button,
.cancel-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.submit-button {
  background-color: #2ecc71;
  color: white;
}

.submit-button:hover {
  background-color: #27ae60;
  transform: translateY(-2px);
}

.submit-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
  transform: none;
}

.cancel-button {
  background-color: #e74c3c;
  color: white;
}

.cancel-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

/* Footer */
.checkout-footer {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  color: #7f8c8d;
}

.security-badges {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.security-badge {
  background-color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: #34495e;
}

/* Responsive Design */
@media (max-width: 768px) {
  .checkout-content {
    flex-direction: column;
  }

  .order-summary,
  .checkout-form {
    width: 100%;
  }
}

/* Error Message */
.error {
  background-color: #fdeaea;
  color: #c0392b;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  border-left: 4px solid #e74c3c;
}
