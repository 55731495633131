.alt-cart {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  transition: all 0.3s ease;
}

.alt-cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f0f0f0;
}

.alt-cart-header h2 {
  font-size: 1.6rem;
  color: #2c3e50;
  font-weight: 600;
  margin: 0;
}

.alt-cart-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.alt-cart-count {
  background-color: #f8f9fa;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #6c757d;
}

.alt-cart-currency {
  background-color: #e9ecef;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #495057;
  font-weight: 500;
}

.alt-cart-empty-message {
  text-align: center;
  color: #95a5a6;
  font-style: italic;
  padding: 30px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
}

.alt-cart-item-wrapper {
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.alt-cart-item-wrapper:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.alt-payment-type-indicator {
  background-color: #f8f9fa;
  padding: 8px 16px;
  font-size: 0.8rem;
  color: #6c757d;
  border-bottom: 1px solid #e9ecef;
}

.alt-cart-item {
  background-color: #ffffff;
  padding: 16px;
}

.alt-cart-item-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.alt-cart-item-row:last-child {
  margin-bottom: 0;
}

.alt-remove-button {
  background-color: #fff;
  border: 1px solid #dee2e6;
  color: #dc3545;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 12px;
  transition: all 0.2s ease;
}

.alt-remove-button:hover {
  background-color: #dc3545;
  color: #fff;
  border-color: #dc3545;
}

.alt-item-thumbnail {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 16px;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alt-item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.alt-item-thumbnail-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
  color: #6c757d;
  font-weight: bold;
  font-size: 1.2rem;
}

.alt-item-info {
  flex: 1;
}

.alt-item-name {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
  font-size: 1.05rem;
}

.alt-item-details {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 4px;
}

.alt-item-condition-select,
.alt-item-printing-select {
  padding: 6px 10px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 0.85em;
  background-color: #fff;
  min-width: 100px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.alt-item-condition-select:focus,
.alt-item-printing-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.alt-item-set,
.alt-item-language,
.alt-item-note {
  background-color: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  display: inline-flex;
  align-items: center;
}

.alt-item-language {
  background-color: #f8d7da;
  color: #721c24;
  font-weight: bold;
}

.alt-item-note {
  background-color: #fff3cd;
  color: #856404;
  cursor: help;
}

.alt-cart-item-controls {
  justify-content: space-between;
  align-items: center;
}

.alt-quantity-control {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 20px;
  padding: 2px;
  border: 1px solid #e0e0e0;
}

.alt-quantity-button {
  background-color: #fff;
  border: none;
  color: #495057;
  font-size: 1rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.alt-quantity-button:hover:not(:disabled) {
  background-color: #e9ecef;
  color: #212529;
}

.alt-quantity-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.alt-item-quantity {
  margin: 0 12px;
  font-weight: bold;
  color: #212529;
  min-width: 20px;
  text-align: center;
}

.alt-item-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.alt-item-price-container:hover {
  background-color: #f8f9fa;
}

.alt-item-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
}

.alt-payment-type-indicator {
  font-size: 0.8rem;
  color: #6c757d;
  margin-top: 4px;
}

.alt-cart-summary {
  margin-top: 24px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.alt-summary-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px 0;
}

.alt-total-row {
  font-weight: bold;
  font-size: 1.2rem;
  color: #212529;
  border-top: 1px dashed #dee2e6;
  padding-top: 12px;
  margin-top: 8px;
}

.alt-summary-note {
  text-align: center;
  font-size: 0.8rem;
  color: #6c757d;
  margin-top: 12px;
}

.alt-cart-actions {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.alt-clear-cart-button,
.alt-checkout-button {
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alt-clear-cart-button {
  background-color: #fff;
  color: #f44336;
  border: 1px solid #f44336;
}

.alt-clear-cart-button:hover:not(:disabled) {
  background-color: #f44336;
  color: white;
}

.alt-checkout-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.2);
}

.alt-checkout-button:hover:not(:disabled) {
  background-color: #43a047;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(76, 175, 80, 0.3);
}

.alt-checkout-button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.2);
}

.alt-clear-cart-button:disabled,
.alt-checkout-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.alt-cart-secondary-actions {
  display: flex;
  gap: 12px;
}

.alt-export-csv-button,
.alt-import-csv-button,
.alt-save-for-later-button,
.alt-load-buylist-button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.alt-export-csv-button {
  color: #00796B;
  border: 1px solid #00796B;
}

.alt-export-csv-button:hover:not(:disabled) {
  background-color: #00796B;
  color: white;
}

.alt-import-csv-button {
  color: #FF9800;
  border: 1px solid #FF9800;
}

.alt-import-csv-button:hover {
  background-color: #FF9800;
  color: white;
}

.alt-save-for-later-button {
  color: #673AB7;
  border: 1px solid #673AB7;
}

.alt-save-for-later-button:hover:not(:disabled) {
  background-color: #673AB7;
  color: white;
}

.alt-load-buylist-button {
  color: #2196F3;
  border: 1px solid #2196F3;
}

.alt-load-buylist-button:hover {
  background-color: #2196F3;
  color: white;
}

.alt-export-csv-button:disabled,
.alt-save-for-later-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Custom Buylist Items Styles */
.alt-custom-buylist-button {
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px;
  margin: 16px 0;
  cursor: pointer;
  width: 100%;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(255, 152, 0, 0.2);
}

.alt-custom-buylist-button:hover {
  background-color: #f57c00;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(255, 152, 0, 0.3);
}

.alt-custom-buylist-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(255, 152, 0, 0.2);
}

.alt-custom-buylist-button::before {
  content: "🛍️";
  margin-right: 10px;
  font-size: 1.2rem;
}

.alt-custom-buylist-container {
  margin: 20px 0;
  padding: 16px;
  background-color: #fff3e0;
  border-radius: 8px;
  border: 1px solid #ffe0b2;
}

.alt-custom-buylist-container h3 {
  margin-top: 0;
  color: #e65100;
  font-size: 1.2rem;
  margin-bottom: 16px;
  border-bottom: 1px solid #ffe0b2;
  padding-bottom: 8px;
}

.alt-custom-buylist-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
}

.alt-custom-buylist-table th,
.alt-custom-buylist-table td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #ffe0b2;
}

.alt-custom-buylist-table th {
  background-color: #fff8e1;
  font-weight: 600;
  color: #e65100;
}

.alt-custom-buylist-table tr:hover {
  background-color: #fff8e1;
}

.alt-custom-item-quantity {
  width: 60px;
  padding: 6px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  text-align: center;
}

.alt-add-custom-item-cash,
.alt-add-custom-item-credit {
  padding: 6px 10px;
  margin: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.alt-add-custom-item-cash {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #2e7d32;
}

.alt-add-custom-item-cash:hover {
  background-color: #2e7d32;
  color: white;
}

.alt-add-custom-item-credit {
  background-color: #e3f2fd;
  color: #1565c0;
  border: 1px solid #1565c0;
}

.alt-add-custom-item-credit:hover {
  background-color: #1565c0;
  color: white;
}

.alt-custom-item-label {
  background-color: #fff3e0;
  color: #e65100;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 600;
}

/* Responsive styles */
@media (max-width: 768px) {
  .alt-cart {
    padding: 16px;
  }
  
  .alt-cart-item {
    padding: 12px;
  }
  
  .alt-cart-item-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .alt-quantity-control {
    margin-bottom: 10px;
  }
  
  .alt-item-price-container {
    align-items: flex-start;
    margin-top: 10px;
  }
  
  .alt-cart-secondary-actions {
    flex-direction: column;
  }
  
  .alt-custom-buylist-table {
    display: block;
    overflow-x: auto;
  }
  
  .alt-add-custom-item-cash,
  .alt-add-custom-item-credit {
    display: block;
    width: 100%;
    margin: 4px 0;
  }
}
