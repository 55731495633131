.mobile-order-completion-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;
}

.mobile-order-completion-popup {
  background-color: #000000;
  padding: 20px;
  border-radius: 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: #ffffff;
  border: 1px solid #333333;
  box-sizing: border-box;
}

/* Processing state */
.mobile-order-processing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.mobile-loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #4CAF50;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Success state */
.mobile-order-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.mobile-success-icon {
  background-color: #4CAF50;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-bottom: 15px;
  box-shadow: 0 2px 10px rgba(76, 175, 80, 0.3);
}

.mobile-order-message {
  margin-bottom: 20px;
}

.mobile-order-message p {
  margin: 8px 0;
  font-size: 15px;
  line-height: 1.4;
}

.mobile-important-notice {
  background-color: #333333;
  border-left: 4px solid #FFC107;
  padding: 15px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
}

.mobile-important-notice strong {
  color: #FFC107;
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

/* Error state */
.mobile-order-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.mobile-error-icon {
  background-color: #F44336;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-bottom: 15px;
  box-shadow: 0 2px 10px rgba(244, 67, 54, 0.3);
}

/* Common styles */
.mobile-order-completion-popup h2 {
  color: #ffffff;
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 500;
}

.mobile-order-completion-popup p {
  margin: 0 0 15px 0;
  font-size: 16px;
  line-height: 1.5;
}

.mobile-close-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 10px;
  border-radius: 0;
}

.mobile-close-button:hover {
  background-color: #45a049;
}

.mobile-close-button:active {
  background-color: #3d8b40;
}

/* Responsive adjustments */
@media (max-width: 320px) {
  .mobile-order-completion-popup {
    padding: 15px;
  }
  
  .mobile-success-icon,
  .mobile-error-icon {
    width: 60px;
    height: 60px;
    font-size: 30px;
  }
  
  .mobile-order-completion-popup h2 {
    font-size: 20px;
  }
  
  .mobile-order-message p,
  .mobile-important-notice,
  .mobile-order-completion-popup p {
    font-size: 14px;
  }
  
  .mobile-close-button {
    padding: 12px 15px;
    font-size: 14px;
  }
}
