.mobile-cart {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
}

.mobile-cart-items {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.mobile-cart-item {
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
  border-radius: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.mobile-cart-item.expanded {
  background-color: rgba(255, 255, 255, 0.1);
}

.mobile-cart-item-header {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.mobile-cart-item-thumbnail {
  width: 40px;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}

.mobile-cart-item-thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.mobile-cart-item-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mobile-cart-item-name {
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-cart-item-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--secondary-text);
}

.mobile-cart-item-condition {
  font-weight: 500;
}

.mobile-cart-item-price {
  font-weight: 500;
  color: var(--text-color);
}

.mobile-cart-item-expand-icon {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text);
  padding: 0 8px;
}

.mobile-cart-item-expanded {
  padding: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-cart-item-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mobile-cart-item-set,
.mobile-cart-item-printing,
.mobile-cart-item-type {
  font-size: 14px;
  color: var(--secondary-text);
  margin-bottom: 4px;
}

.mobile-cart-item-printing {
  font-weight: 500;
}

.mobile-cart-item-controls {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mobile-cart-quantity {
  display: flex;
  align-items: center;
  gap: 12px;
}

.mobile-cart-quantity-button {
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: var(--text-color);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mobile-cart-quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mobile-cart-quantity-value {
  font-size: 16px;
  min-width: 24px;
  text-align: center;
}

.mobile-cart-item-actions {
  display: flex;
  gap: 8px;
}

.mobile-cart-toggle-type,
.mobile-cart-remove {
  flex: 1;
  padding: 10px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.mobile-cart-toggle-type {
  background-color: var(--wp8-blue);
  color: white;
}

.mobile-cart-remove {
  background-color: var(--wp8-red);
  color: white;
}

.mobile-cart-summary {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-cart-totals {
  margin-bottom: 16px;
}

.mobile-cart-subtotal,
.mobile-cart-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.mobile-cart-total {
  font-weight: 500;
  font-size: 18px;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-cart-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile-cart-checkout,
.mobile-cart-clear,
.mobile-cart-continue {
  padding: 12px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.mobile-cart-checkout {
  background-color: var(--wp8-green);
  color: white;
}

.mobile-cart-clear {
  background-color: var(--wp8-red);
  color: white;
}

.mobile-cart-continue {
  background-color: var(--wp8-blue);
  color: white;
}

.mobile-cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--header-height) - 32px);
  padding: 16px;
  text-align: center;
}

.mobile-cart-empty p {
  margin-bottom: 24px;
  font-size: 18px;
  color: var(--secondary-text);
}

.mobile-cart-back-button {
  padding: 12px 24px;
  background-color: var(--wp8-blue);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
