.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.mobile-header-left,
.mobile-header-right {
  display: flex;
  align-items: center;
}

.mobile-header-title {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.mobile-header-button {
  background: none;
  border: none;
  color: var(--text-color);
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0 4px;
}

.mobile-header-button.active {
  color: var(--wp8-blue);
}

.mobile-header-button svg {
  width: 24px;
  height: 24px;
}

.cart-badge {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--wp8-red);
  color: white;
  font-size: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
