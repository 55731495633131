.price-inquiry-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.price-inquiry-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
}

.price-inquiry-form h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.price-inquiry-form form > div {
  margin-bottom: 15px;
}

.price-inquiry-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.price-inquiry-form input[type="email"],
.price-inquiry-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.price-inquiry-form textarea {
  height: 150px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.price-inquiry-form button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.price-inquiry-form button[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

.price-inquiry-form button[type="submit"]:hover {
  background-color: #45a049;
}

.price-inquiry-form button[type="button"] {
  background-color: #f44336;
  color: white;
}

.price-inquiry-form button[type="button"]:hover {
  background-color: #d32f2f;
}

.price-inquiry-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #f44336;
  margin-top: 10px;
}

.success-message {
  color: #4CAF50;
  margin-top: 10px;
  font-weight: bold;
}
