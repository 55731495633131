.mobile-card-list {
  margin-top: 16px;
}

/* Mobile Toggle Container Styles */
.mobile-toggle-container {
  background-color: var(--wp8-blue);
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-view-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 12px;
}

.mobile-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.mobile-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.mobile-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.mobile-slider.round {
  border-radius: 34px;
}

.mobile-slider.round:before {
  border-radius: 50%;
}

input:checked + .mobile-slider {
  background-color: #4CAF50;
}

input:focus + .mobile-slider {
  box-shadow: 0 0 1px #4CAF50;
}

input:checked + .mobile-slider:before {
  transform: translateX(26px);
}

.mobile-toggle-label {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.mobile-card-item {
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
  border-radius: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.mobile-card-item.expanded {
  background-color: rgba(255, 255, 255, 0.1);
}

.mobile-card-item.excluded {
  opacity: 0.6;
}

.mobile-card-header {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.mobile-card-thumbnail {
  width: 50px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}

.mobile-card-thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.mobile-card-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mobile-card-name {
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-card-set {
  font-size: 12px;
  color: var(--secondary-text);
}

.mobile-card-number {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
  margin-top: 2px;
}

.mobile-card-not-buying {
  color: var(--wp8-red);
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
}

.mobile-card-expand-icon {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text);
  padding: 0 8px;
}

.mobile-card-details {
  padding: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-card-selectors {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-card-condition-selector,
.mobile-card-printing-selector,
.mobile-card-quantity {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile-card-condition-selector label,
.mobile-card-printing-selector label,
.mobile-card-quantity label {
  font-size: 14px;
  color: var(--secondary-text);
}

.mobile-printing-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.mobile-printing-button {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: var(--text-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-printing-button.active {
  background-color: var(--wp8-blue);
}

.mobile-condition-buttons {
  display: flex;
  gap: 8px;
}

.mobile-condition-button {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: var(--text-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-condition-button.active {
  background-color: var(--wp8-blue);
}

.mobile-quantity-control {
  display: flex;
  align-items: center;
  gap: 12px;
}

.mobile-quantity-button {
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: var(--text-color);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mobile-quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mobile-quantity-value {
  font-size: 16px;
  min-width: 24px;
  text-align: center;
}

.mobile-card-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.mobile-add-cash,
.mobile-add-credit {
  padding: 12px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.mobile-add-cash {
  background-color: var(--wp8-green);
  color: white;
}

.mobile-add-credit {
  background-color: var(--wp8-blue);
  color: white;
}

.mobile-contact-for-price {
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--secondary-text);
  text-align: center;
  font-size: 14px;
}

.mobile-no-results {
  padding: 24px;
  text-align: center;
  color: var(--secondary-text);
}
