.enquiry-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.enquiry-popup {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.enquiry-popup-content h2 {
  margin-top: 0;
  color: #333;
}

.enquiry-popup-content p {
  margin-bottom: 20px;
  color: #666;
}

.enquiry-popup-buttons {
  display: flex;
  justify-content: flex-end;
}

.enquiry-popup-buttons button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.continue-button {
  background-color: #4CAF50;
  color: white;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.continue-button:hover {
  background-color: #45a049;
}
