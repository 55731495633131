.bulk-search-page {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bulk-search-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.bulk-search-description {
  margin-bottom: 20px;
  color: #666;
  line-height: 1.5;
}

.bulk-search-actions {
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
}

.search-all-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.search-all-button:hover {
  background-color: #45a049;
}

.search-all-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive styles */
@media (max-width: 768px) {
  .bulk-search-page {
    padding: 15px;
  }
  
  .bulk-search-title {
    font-size: 20px;
  }
  
  .search-all-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
