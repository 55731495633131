.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
}

.terms-content {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #444;
}

.close-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #3a7bc8;
}

@media (max-width: 768px) {
  .popup-content {
    max-width: 90%;
    max-height: 90%;
  }
}